<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A buffer is a solution containing a weak <b>&nbsp;[A]&nbsp;</b> and its
        <b>&nbsp;[B]&nbsp;</b> or a weak <b>&nbsp;[C]&nbsp;</b> and its <b>&nbsp;[D]</b>. A buffer
        solution <b>&nbsp;[E]</b>.
      </p>
      <p class="mb-4">
        The point at which a buffer can no longer resist changes in pH in the presence of an acid or
        a base is called the <b>&nbsp;[F]</b>.
      </p>
      <p class="mb-4">
        What is a valid way to make an ammonia/ammonium buffer for use in the laboratory? Mix equal
        volumes of <b>&nbsp;[G]</b>.
      </p>

      <!--  first 4 have the same options    -->
      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; width: 300px"
          class="my-1 ml-3"
          :items="optionsAToD"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [E]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; width: 300px"
          class="my-1 ml-3"
          :items="optionsE"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [F]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; width: 300px"
          class="my-1 ml-3"
          :items="optionsF"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p>
        [G]:
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; width: 300px"
          class="my-1 ml-3"
          :items="optionsG"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S1_Q5',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      optionsAToD: [
        {text: 'acid', value: 'acid'},
        {text: 'base', value: 'base'},
        {text: 'buffer capacity', value: 'buffer'},
        {text: 'conjugate acid', value: 'conjugateAcid'},
        {text: 'conjugate base', value: 'conjugateBase'},
      ],
      optionsE: [
        {text: 'has a pH of 7', value: 'seven'},
        {text: 'resists small changes in pH', value: 'resists'},
        {text: 'reacts with acid & base solutions', value: 'reacts'},
        {text: 'always changes color with pH change', value: 'color'},
        {text: 'two of these', value: 'two'},
      ],
      optionsF: [
        {text: 'acid', value: 'acid'},
        {text: 'base', value: 'base'},
        {text: 'conjugate acid', value: 'conjugateAcid'},
        {text: 'conjugate base', value: 'conjugateBase'},
        {text: 'all of these', value: 'all'},
        {text: 'buffer capacity', value: 'buffer'},
      ],
      optionsG: [
        {text: '$\\ce{1M NH_{3} \\& 1M NH_{4}^{+}}$', value: 'optionA'},
        {text: '$\\ce{1M NH_{3} \\& half as much 1M HCl}$', value: 'optionB'},
        {text: '$\\ce{1M NH_{3} \\& 1M HCl}$', value: 'optionC'},
        {text: '$\\ce{1M NH_{3} \\& 0.01M NH_{4}^{+}}$', value: 'optionD'},
        {text: 'Two of these', value: 'two'},
        {text: 'All of these', value: 'all'},
      ],
      letterOptions: [
        {text: '[A]: ', inputName: 'input1'},
        {text: '[B]: ', inputName: 'input2'},
        {text: '[C]: ', inputName: 'input3'},
        {text: '[D]: ', inputName: 'input4'},
      ],
    };
  },
};
</script>
